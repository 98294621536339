<template>
  <List
    title="customers"
    resource="appCustomers"
    :basePath="basePath"
    :fields="fields"
    track-by="customerId"
    :request-params="{appId: appId, nonDeleted: true}"
    :sort-order="sortOrder"
    ref="list"
    @row-clicked="handleRowClick"
    :search="true"
    search-placeholder="search for a customer"
    :initial-filter="filter"
  >
    <template v-slot:filters="filterScope">
      <div class="form-row">
        <div class="form-col">
          <SelectInput
            name="status"
            label="status"
            :options="accessOptions"
          />
        </div>
      </div>
      <div class="form-row justify-end">
        <button @click.stop="filterScope.reset()" class="btn-primary btn-transparent mr-8">reset</button>
        <button class="btn-primary">filter</button>
      </div>
    </template>
    <template v-slot:inline-actions="actionsScope">
      <button
        class="btn-action-warning"
        v-if="actionsScope.record.status === 'ACTIVE'"
        @click.stop="handleRevokeAccess(actionsScope.record)"
      >
        <icon class="w-3 h-3 mr-1" name="close"/>
        revoke
      </button>
      <button
        class="btn-action-success"
        v-if="actionsScope.record.status === 'REVOKED'"
        @click.stop="handleRestoreAccess(actionsScope.record)"
      >
        <icon class="w-3 h-3 mr-1" name="rotate"/>
        restore
      </button>
    </template>
  </List>
</template>

<script>
  import List from "@/components/auth/list/List";
  import ModalNavigation from "@/mixins/ModalNavigation";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Icon from "@/components/ui/Icon";
  import EventBus from "@/utils/EventBus";
  import SelectInput from "@/components/form/SelectInput";

  export default {
  name: "AppCustomersList",
  components: {
    Icon,
    List,
    SelectInput
  },
  mixins: [ModalNavigation, NotifyMixin],
  data: function () {
    return {
      filter: {
        status: undefined,
      },
      sortOrder: [
        {
          field: 'customerName',
          direction: 'asc'
        }
      ],
      accessOptions: ['ACTIVE', 'REVOKED', 'ALL'].map(o => ({key: o, value: o.toLowerCase()})),
      fields: [
        {
          name: 'customerName',
          title: 'customer',
          sortField: 'customerName',
        },
        {
          name: 'status',
          title: 'access',
          sortField: 'status',
        },
        {
          name: 'inline-actions',
          title: '',
          width: "15%",
        }
      ],
      app: undefined,
    };
  },
  computed: {
    basePath: function () {
      return this.$route.path;
    },
    appId: function () {
      return this.$route.params.appId;
    },
  },
  methods: {
    handleRowClick({data: {id: appAccessId, appId, customerId}}) {
      this.$router.replace({name: 'applications.customers.users', params: {appAccessId, appId, customerId}});
    },
    handleRevokeAccess({customerName, id: appAccessId, appId}) {
      EventBus.emit('show-modal-confirmation', {
        id: 'auth-revoke-customer-access',
        title: 'revoke customer access',
        showConfirmation: true,
        confirmationMessage: `are you sure you want to Revoke Access for ${customerName} customer?`,
        confirmationType: 'warning',
        confirmBtnText: 'yes, revoke',
      });

      const revokeAccess = () => {
        this.$authDataProvider.create('appCustomersRevoke', {appId, appAccessId})
          .then(() => {
            this.$refs.list.doSearch();
            this.notifySuccess(`Access for ${customerName} revoked`);
          })
          .catch(error => this.notifyError(error.message));

        EventBus.off('confirm-auth-revoke-customer-access', revokeAccess);
      };

      EventBus.on('confirm-auth-revoke-customer-access', revokeAccess);

      EventBus.on('cancel-auth-revoke-customer-access', () => {
        EventBus.off('confirm-auth-revoke-customer-access', revokeAccess);
      })
    },
    handleRestoreAccess({customerName, id: appAccessId, appId}) {
      EventBus.emit('show-modal-confirmation', {
        id: 'auth-restore-customer-access',
        title: 'restore customer access',
        showConfirmation: true,
        confirmationMessage: `are you sure you want to Restore Access for ${customerName} customer?`,
        confirmationType: 'success',
        confirmBtnText: 'yes, restore',
      });

      const restoreAccess = () => {
        this.$authDataProvider.create('appCustomersRestore', {appId, appAccessId})
          .then(() => {
            this.$refs.list.doSearch();
            this.notifySuccess(`Access for ${customerName} restored`);
          })
          .catch(error => this.notifyError(error.message));

        EventBus.off('confirm-auth-restore-customer-access', restoreAccess);
      };

      EventBus.on('confirm-auth-restore-customer-access', restoreAccess);

      EventBus.on('cancel-auth-restore-customer-access', () => {
        EventBus.off('confirm-auth-restore-customer-access', restoreAccess);
      })
    }
  },
  mounted() {
    this.$authDataProvider.getOne('apps', {id: this.appId})
      .then(app => {
        this.app = app;
        this.setActiveModalTitle(`customer access to ${app.name} application`);
      })
      .catch(error => this.notifyError(error.message));
  }
}
</script>
